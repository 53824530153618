import authorImg1 from "../../assets/images/management/anwar.webp";
import authorImg2 from "../../assets/images/management/wangCheow.webp";
import authorImg3 from "../../assets/images/management/gangesh.webp";
import authorImg4 from "../../assets/images/management/gilliam.webp";
import authorImg5 from "../../assets/images/management/farzan.webp";
import authorImg6 from "../../assets/images/management/puneet-k.webp";
import authorImg8 from "../../assets/images/management/author8.jpg";
import authorImg9 from "../../assets/images/management/author9.jpg";
import authorImg10 from "../../assets/images/management/author10.jpg";

export const managementDetails = [
  {
    id: 1,
    authorImg: authorImg1,
    name: "Anwar Rafique",
    designation: "Co-Founder & CEO",
    text: (
      <>
        {" "}
        <p className="text-base  mb-2  text-[#757575] leading-[24px] font-normal overflow-hidden text-justify">
          <b>Mr. Anwar Rafique</b> demonstrates an impressive background and domain expertise. He has 2 decades of diversified experience within
          banking and financial services. Anwar holds a master’s degree in business and accounting, spending his corporate life primarily in
          Singapore.
        </p>
        <p className="text-base  mb-2  text-[#757575] leading-[24px] font-normal overflow-hidden text-justify">
          Anwar believe in giving back to the community and is a grassroots leader engaged in volunteer services. He is fascinated with all new
          technology like AR/VR, AI &amp; Machine Learning and how this can be used to make more efficient and affordable digital healthcare service
          to the community. Before starting his entrepreneurial journey, Anwar was heading strategy &amp; risk team with top tier bank in Singapore.
        </p>
      </>
    ),
    link: "https://www.linkedin.com/in/anwar-r-964906b/",
  },
  {
    id: 2,
    authorImg: authorImg3,
    name: "Mr. Gangesh Kumar Jha",
    designation: "Co-Founder & COO",
    text: (
      <>
        {" "}
        <p className=" text-base  mb-2  text-[#757575] leading-[24px] font-normal overflow-hidden text-justify">
          <b>Mr. Gangesh Kumar Jha</b> has a combined 20 years of experience in Supply Chain Technology, healthcare, and leadership. Dynamic, problem
          solver and team leader with expertise in developing partnerships internal & external. Mr Gangesh ensures that our business is
          well-coordinated and productive by managing its procedures and coaching its people. His core strength is to combine skills in Product
          Management and deploy design led digital and AI technologies aimed at addressing health system efficiency. He is an excellent leader who can
          discover the most efficient ways to run the business.
        </p>
      </>
    ),
    link: "https://www.linkedin.com/in/gangesh-kumar-jha-gj-7859911b/",
  },
  {
    id: 3,
    authorImg: authorImg5,
    name: "Farzan Siddiqui",
    designation: "Chief Technology Officer",
    text: (
      <>
        {" "}
        <p className=" text-base  mb-2  text-[#757575] leading-[24px] font-normal overflow-hidden text-justify">
          <b>Mr. Farzan Siddiqui</b> brings significant experience in building customer-centric software through Lean and Agile development practices.
          Farzan brings to MyCLNQ more than 20 years of experience in leading technology at multinationals. His strong background in Digital solutions
          and scaling software development teams, developed at leading technology companies including DXC, NTT, DELL, NESS, SOURCEBITS and WIPRO,
          playing a key role in MyCLNQ’s success.
        </p>
        <p className="text-base  mb-2  text-[#757575] leading-[24px] font-normal overflow-hidden text-justify">
          Farzan is leading the product development teams to scale effectively and sustainably in line with organisational growth, ensuring a
          continued focus on quality, customer-centricity and innovation for the MyCLNQ Health systems supporting connected health and related
          systems.
        </p>
      </>
    ),
    link: "https://www.linkedin.com/in/farzans/",
  },
  {
    id: 4,
    authorImg: authorImg8,
    name: "Pragnesh Patel",
    designation: "Chief Strategy & Growth Officer",
    text: (
      <>
        {" "}
        <p className="text-base  mb-2  text-[#757575] leading-[24px] font-normal text-justify">
          <b>Pragnesh Patel</b>, a first-generation entrepreneur with over 15 years of extensive management experience, is renowned for his
          outstanding proficiency in strategic planning, business analysis, and community empowerment. Pragnesh's journey began with his tenure in
          Nigeria, he gained invaluable insights into consumer markets and marketing strategies. Further honed his expertise in roles at Abbott
          Pharmaceuticals and Claris Lifesciences (Now Baxter Pharmaceuticals), where he showcased his adeptness in strategic planning and business
          analysis.
        </p>
        <p className="text-base  mb-2  text-[#757575] leading-[24px] font-normal text-justify">
          Pragnesh's multifaceted skill set, combined with his unwavering commitment to making a positive impact, positions him as a catalyst for both
          business and community development initiatives. His dedication to empowering Young generations communities and driving positive change
          underscores his role as a pivotal figure in fostering growth and prosperity. Recognized for his skills, Pragnesh has received the Young
          Entrepreneur Award from India's Achiever Forum.
        </p>
      </>
    ),
    link: "https://www.linkedin.com/in/pragnesh-patel-893b5aa/",
  },
  {
    id: 5,
    authorImg: authorImg9,
    name: "Bharat Maheshwari",
    designation: "Business Advisor",
    text: (
      <>
        <p className="text-base  mb-2  text-[#757575] leading-[24px] font-normal text-justify">
          <b>Bharat Maheshwari</b>, an esteemed entrepreneur from Valsad, Gujarat, boasts over 25 years of experience in HR & Administration,
          transitioning seamlessly to become a mentor in Startup India. As the driving force behind Gangotri Organics, Bharat's expertise extends to
          diverse sectors, including organic farming, biofuel additives, and eco-friendly products. His commitment to fostering sustainability is
          evident in his mentorship role, guiding budding entrepreneurs towards environmentally conscious business practices.
        </p>
        <p className="text-base  mb-2  text-[#757575] leading-[24px] font-normal text-justify">
          Recognized for his exemplary leadership, Bharat Maheshwari has been honored with the prestigious Green Leadership Award from India CSR. His
          dedication to nurturing startups and promoting sustainability underscores his pivotal role in shaping a greener, more prosperous future for
          generations to come.
        </p>
      </>
    ),
    link: "https://www.linkedin.com/in/bharat-maheshwari-1475b58/",
  },
  
  {
    id: 6,
    authorImg: authorImg4,
    name: "Dr. Yvanka Gilliam, MBA",
    designation: "Medical Advisor",
    text: (
      <>
        <p className="text-base  mb-2  text-[#757575] leading-[24px] font-normal overflow-hidden text-justify">
          <b>Dr. Yvanka Gilliam</b> has over 23 years of clinical operations, medical affairs, health economics outcomes research (HEOR), Business
          Development, and Market access experience focusing on Emerging markets within the Pharmaceutical, Biotechnology, and Diagnostic industries.
        </p>
        <p className="text-base  mb-2  text-[#757575] leading-[24px] font-normal overflow-hidden text-justify">
          She is the CEO and Founder of Clinical Research Advancement, Inc. based in the US and Clinical Research Advancement Pte. Ltd. based in
          Singapore.
        </p>
        <p className="text-base  mb-2  text-[#757575] leading-[24px] font-normal text-justify">
          Yvanka is currently the Chief Clinical Officer for Reveles, Inc. and the Head of Clinical Alliances for Singapore Clinical Research
          Institute (SCRI).
        </p>
        <p className="text-base  mb-2  text-[#757575] leading-[24px] font-normal text-justify">
          Yvanka has served as the Vice President of Operations for Diaceutics within the Asia Pacific, Middle East &amp; Africa regions. Before
          joining Diaceutics, she was the VP of Clinical Affairs and Real World Evidence (RWE) with Kantar Health. She has also served as the VP, Head
          of Real World Evidence at IQVIA Singapore.
        </p>
      </>
    ),
    link: "https://www.linkedin.com/in/yvankagilliam/",
  },
  {
    id: 7,
    authorImg: authorImg6,
    name: "Dr. Puneet K. Kochhar",
    designation: "Medical Advisor",
    text: (
      <>
        {" "}
        <p className=" text-base  mb-2  text-[#757575] leading-[24px] font-normal overflow-hidden text-justify">
          <b>Dr. Puneet K. Kochhar</b> is a medical advisor to MyCLNQ and clinician par excellence with extensive experience in the field of ART.
          She has previously worked as Assistant Professor at the prestigious Lady Hardinge Medical College, Delhi and as Consultant Gynaecologist and
          IVF specialist at the world’s First IVF center at Bourn Hall Clinic in Cambridgeshire, UK, and has performed thousands of ART related
          procedures (ovum pick ups, embryo transfers, hysteroscopies and laparoscopies) while consistently delivering high pregnancy rates.
        </p>
        <p className="text-base  mb-2  text-[#757575] leading-[24px] font-normal overflow-hidden text-justify">
          She has numerous publications in national and international journals, and is a reviewer for variousjournals. She has consistently been
          awarded top honors in various conferences for oral and poster presentations
        </p>
      </>
    ),
    link: "https://www.linkedin.com/in/puneet-kochhar-82b82725/",
  },
  {
    id: 8,
    authorImg: authorImg10,
    name: "Dr. Sandeep Sharma",
    designation: "Country Director (India)",
    text: (
      <>
        {" "}
        <p className=" text-base  mb-2  text-[#757575] leading-[24px] font-normal overflow-hidden text-justify">
          <b>Dr. Sandeep Sharma</b> is an accomplished medical and insurance professional with over 20 years of experience in the Insurance, Healthcare, and Assistance sectors. Throughout his career, he has worked with a variety of key industry players, including insurers, third-party administrators, and global assistance companies, focusing on business development, operations, and medical claims management. His expertise spans both international and domestic markets.
        </p>
        <p className="text-base  mb-2  text-[#757575] leading-[24px] font-normal overflow-hidden text-justify">
          Dr. Sandeep has held senior leadership roles at prominent organizations such as Paramount Healthcare, ICICI Prudential, Assist America, and Across Assist. In addition to his significant contributions to the insurance and assistance industries, he holds a medical degree from Mumbai University. His clinical experience includes serving as a Clinical Medical Officer at ALERT INDIA (NGO) and practicing as a General Physician for 10 years.
        </p>
        <p className="text-base  mb-2  text-[#757575] leading-[24px] font-normal overflow-hidden text-justify">
          Dr. Sandeep is also actively involved with several medical associations in India. His comprehensive background in both clinical medicine and the insurance sector allows him to bridge the gap between healthcare delivery and insurance services effectively.
        </p>
      </>
    ),
    link: "https://www.linkedin.com/in/drsandeeprsharma/",
  },
];
