import React, { lazy, Suspense } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import MissingPage from "./Pages/404Page";
import FAQ from "./Pages/FAQ";
import GetApp from "./Pages/GetApp";
import ArticleAllergy from "./Pages/ArticleAllergy";
import NewsAi from "./Pages/NewsAi";
import ArticleEndocarditis from "./Pages/ArticleEndocarditis";
import ArticleEye from "./Pages/ArticleEye";
import ArticleMigrane from "./Pages/ArticleMigrane";
import ArticleRemedies from "./Pages/ArticleRemedies";
import ArticleSleep from "./Pages/ArticleSleep";
import ArticleStomach from "./Pages/ArticleStomach";
import Legal from "./Pages/Legal";
import NewsAngMedical from "./Pages/NewsAngMedical";
import NewsCellcardPartner from "./Pages/NewsCellcardPartner";
import NewsMinister from "./Pages/NewsMinister";
import NewsFinancialInclusion from "./Pages/NewsFinancialInclusion";
import MindVisionClinic from "./Pages/MindVisionClinic";
import HealthCarePackage from "./Pages/HealthCarePackage";
import SathyaClinicPage from "./Pages/SathyaClinicPage";
import MediaClubIndiaClinic from "./Pages/MediaClubIndiaClinic";
import BookDoctorAppointment from "./Pages/BookDoctorAppointment";
import DrRaziqueENTClinic from "./Pages/DrRaziqueENTClinic";
import LoadingIcon from "./Components/LoadingIcon";
import Event from "./Pages/Event";
import Management from "./Pages/Management";
import Nervfit from "./Pages/NewsNervfit";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for styling

const Home = lazy(() => import("./Pages/Home"));
const About = lazy(() => import("./Pages/About"));
const Contact = lazy(() => import("./Pages/Contact"));
const DoctorsForYou = lazy(() => import("./Pages/DoctorsForYou"));
const CareWagon = lazy(() => import("./Pages/CareWagon"));
const CareGiver = lazy(() => import("./Pages/CareGiver"));
const GetQuote = lazy(() => import("./Pages/GetQuote"));
const HealthCareBooking = lazy(() => import("./Pages/HealthCareBooking"));
const NewsArticleHomePage = lazy(() => import("./Pages/NewsArticleHomePage"));
const Doctorsecondopinon = lazy(() => import("./Pages/doctor-second-opinon"));

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Suspense fallback={<LoadingIcon />}>
                <Home />
              </Suspense>
            }
          />
            <Route
            exact
            path="/:section?"
            element={
              <Suspense fallback={<LoadingIcon />}>
                <Home />
              </Suspense>
            }
          />
          
          
          <Route
            path="/about"
            element={
              <Suspense fallback={<LoadingIcon />}>
                <About />
              </Suspense>
            }
          />
           <Route
            path="/management"
            element={
              <Suspense fallback={<LoadingIcon />}>
                <Management />
              </Suspense>
            }
          />
          <Route
            path="/doctorsForYou"
            element={
              <Suspense fallback={<LoadingIcon />}>
                <DoctorsForYou />
              </Suspense>
            }
          />
          <Route
            path="/CareWagon"
            element={
              <Suspense fallback={<LoadingIcon />}>
                <CareWagon />
              </Suspense>
            }
          />
          <Route
            path="/CareGiver"
            element={
              <Suspense fallback={<LoadingIcon />}>
                <CareGiver />
              </Suspense>
            }
          />
          <Route
            path="/contact"
            element={
              <Suspense fallback={<LoadingIcon />}>
                <Contact />
              </Suspense>
            }
          />
          <Route
            path="/GetQuote"
            element={
              <Suspense fallback={<LoadingIcon />}>
                <GetQuote />
              </Suspense>
            }
          />
          <Route
            path="/healthCareBooking"
            element={
              <Suspense fallback={<LoadingIcon />}>
                <HealthCareBooking />
              </Suspense>
            }
          />
          <Route
            path="/newsArticles"
            element={
              <Suspense fallback={<LoadingIcon />}>
                <NewsArticleHomePage />
              </Suspense>
            }
          />

          <Route
            path="/events"
            element={
              <Suspense fallback={<LoadingIcon />}>
                <Event />
              </Suspense>
            }
          />
          
          <Route path="/articleAllergy" element={<ArticleAllergy />} />
          <Route
            path="/articleEndocarditis"
            element={<ArticleEndocarditis />}
          />

          <Route path="/articleEye" element={<ArticleEye />} />
          <Route path="/articleMigrane" element={<ArticleMigrane />} />
          <Route path="/articleRemedies" element={<ArticleRemedies />} />
          <Route path="/articleSleep" element={<ArticleSleep />} />
          <Route
            path="/articleStomachTrouble"
            element={<ArticleStomach />}
          />
          <Route path="/newsAi" element={<NewsAi />} />
          <Route path="/newsAngMedical" element={<NewsAngMedical />} />
          <Route path="/nervfit" element={<Nervfit />} />
          <Route
            path="/newsCellCardPartner"
            element={<NewsCellcardPartner />}
          />
          <Route path="/newsMinister" element={<NewsMinister />} />
          <Route
            path="/newsFinanceInclusion"
            element={<NewsFinancialInclusion />}
          />
          <Route path="/healthCarePackage" element={<HealthCarePackage />} />
          <Route
            path="/bookDoctorAppointment"
            element={<BookDoctorAppointment />}
          />
          <Route path="/MindVisionClinic" element={<MindVisionClinic />} />
          <Route path="/SatyaSkinClinic" element={<SathyaClinicPage />} />
          <Route
            path="/MediaClubIndiaClinic"
            element={<MediaClubIndiaClinic />}
          />
          <Route path="/DrRaziqueENTClinic" element={<DrRaziqueENTClinic />} />
          <Route path="/FAQ" element={<FAQ />} />
          <Route path="/GetApp" element={<GetApp />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="*" element={<MissingPage />} />
          <Route
            exact
            path="/doctor-second-opinion"
            element={
              <Suspense fallback={<LoadingIcon />}>
                <Doctorsecondopinon/>
              </Suspense>
            }
          />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
